import { Typography } from '@mui/material'
import React from 'react'

type Props = {}

function IDEFooter({ }: Props) {
    return (
        <div>
            <h3>

            </h3>
            <Typography component={'div'}>

            </Typography>
        </div>
    )
}

export default IDEFooter